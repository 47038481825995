import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-unavailable',
  templateUrl: './app-unavailable.component.html',
  styleUrls: ['./app-unavailable.component.less'],
})
export class AppUnavailableComponent {
  @Input() public message: string | undefined | null;
}
