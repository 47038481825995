import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, throwError } from 'rxjs';
import { LayoutService } from '@components/layouts/layout.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {

  constructor(
    private modal: NzModalService,
    private layoutService: LayoutService,
  ) {
  }

  manage(error: any): Observable<never> {
    this.layoutService.stopLoading();
    this.modal.error({
      nzTitle: error.error?.code || error.name || error.code || 'Erreur',
      nzOkText: 'ok',
      nzContent: `${error.error?.message || error.error?.messageToDisplay || error.message || error.name}`,
    });

    return throwError(() => error);
  }
}
