import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  catchError,
  defer,
  first,
  from,
  map,
  Observable,
  switchMap,
  tap, throwError,
} from 'rxjs';
import { LoginRequest } from '@assets/requests/auth/Login.request';
import { ErrorsService } from '@services/errors.service';
import { UserService } from '@services/user.service';
import {
  Auth,
  user,
  User,
  signInWithEmailAndPassword,
  signOut,
} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  #userService: UserService = inject(UserService);
  #errorsService: ErrorsService = inject(ErrorsService);
  #router: Router = inject(Router);
  #afAuth: Auth = inject(Auth);
  user$: Observable<User | null> = user(this.#afAuth);

  isLoggedIn$: Observable<boolean> = this.user$.pipe(
    map((user: User | null) => !!user),
  );

  constructor() {
    this.user$.pipe(
      switchMap((user: User | null) => !!user ? this.onLogin$() : this.#onLogout$()),
    ).subscribe();
  }

  login$(request: LoginRequest): Observable<void> {
    const { userName, password }: LoginRequest = request;
    return from(signInWithEmailAndPassword(this.#afAuth, userName, password))
      .pipe(
        tap(() => this.#router.navigate(['/'])),
        map(() => void 0),
        catchError(() => this.#errorsService.manage({
          name: `L'authentication a échouée`,
          message: `Accès refusé`,
        })));
  }

  logout(): void {
    void signOut(this.#afAuth);
    this.#userService.clearUser();
  }

  onLogin$(): Observable<void> {
    return this.user$.pipe(
      first(),
      switchMap((afUser: User | null) => {
        if (!afUser) {
          throw new Error('Login with FB failed');
        }
        return this.#userService.initUserById$(afUser.uid);
      }),
      catchError((e) => {
        this.logout();
        return throwError(() => e);
      }),
    );
  }

  #onLogout$(): Observable<void> {
    return defer(() => {
      this.#userService.clearUser();
      return from(this.#router.navigate(['/auth/login']))
        .pipe(
          map(() => void 0),
        );
    });
  }
}