<ng-container *ngIf="loadingAvailability$ | async; else appScreen">
    <div class="fullscreen-wrapper">
        <nz-spin nzSimple nzSize="large"></nz-spin>
    </div>
</ng-container>

<ng-template #appScreen>
    <ng-container *ngIf="isAvailable$ | async; else unavailabilityMessage">
        <router-outlet></router-outlet>
    </ng-container>
</ng-template>

<ng-template #unavailabilityMessage>
    <app-unavailable [message]="unavailabilityMessage$ | async"></app-unavailable>
</ng-template>
