export const CURRENT_ENV: string = 'DEV';
export const apiMapping = {
  "createAgencyMandate": {
    "functionName": "createAgencyMandate",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/mandate/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "createOneAgency": {
    "functionName": "createOneAgency",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgenciesByContractType": {
    "functionName": "getAgenciesByContractType",
    "path": "https://dev.apistayopen.com/admin/agencies/:contractType",
    "verb": "GET",
    "authType": "JWT"
  },
  "getAgencyMandate": {
    "functionName": "getAgencyMandate",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/mandate/get",
    "verb": "GET",
    "authType": "JWT"
  },
  "getAgencyPaymentBankAccountAlias": {
    "functionName": "getAgencyPaymentBankAccountAlias",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/:agencyId/external-bank-wire",
    "verb": "GET",
    "authType": "JWT"
  },
  "getAllAgencies": {
    "functionName": "getAllAgencies",
    "path": "https://dev.apistayopen.com/agencies",
    "verb": "GET",
    "authType": "JWT"
  },
  "getAllAgenciesByCompanyId": {
    "functionName": "getAllAgenciesByCompanyId",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies",
    "verb": "GET",
    "authType": "JWT"
  },
  "getAllAgenciesOfAgencyAdmin": {
    "functionName": "getAllAgenciesOfAgencyAdmin",
    "path": "https://dev.apistayopen.com/companies/:companyId/agency-admin/:agencyAdminId/agencies",
    "verb": "GET",
    "authType": "JWT"
  },
  "getAllAgenciesPerPage": {
    "functionName": "getAllAgenciesPerPage",
    "path": "https://dev.apistayopen.com/agencies/paginate",
    "verb": "POST",
    "authType": "JWT"
  },
  "getExportingAllAgencies": {
    "functionName": "getExportingAllAgencies",
    "path": "https://dev.apistayopen.com/agencies/export",
    "verb": "GET",
    "authType": "JWT"
  },
  "getOneAgency": {
    "functionName": "getOneAgency",
    "path": "https://dev.apistayopen.com/agencies/:agencyId",
    "verb": "GET",
    "authType": "JWT"
  },
  "updateAgencyContract": {
    "functionName": "updateAgencyContract",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/:agencyId/contract",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateAgencyPayment": {
    "functionName": "updateAgencyPayment",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/:agencyId/payment",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateOneAgency": {
    "functionName": "updateOneAgency",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/:agencyId/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateAgencyRestitution": {
    "functionName": "updateAgencyRestitution",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/:agencyId/restitution",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgencyPaymentInformation": {
    "functionName": "getAgencyPaymentInformation",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/:agencyId/payment-information",
    "verb": "GET",
    "authType": "JWT"
  },
  "getBeneficiaryClassificationForAgency": {
    "functionName": "getBeneficiaryClassificationForAgency",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/:agencyId/beneficiary-classification",
    "verb": "GET",
    "authType": "JWT"
  },
  "createOneBeneficiaryClassification": {
    "functionName": "createOneBeneficiaryClassification",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/:agencyId/beneficiary-classification/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateOneBeneficiaryClassification": {
    "functionName": "updateOneBeneficiaryClassification",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/:agencyId/beneficiary-classification/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "removeOneBeneficiaryClassification": {
    "functionName": "removeOneBeneficiaryClassification",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/:agencyId/beneficiary-classification/remove",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllAgencyManagementUnits": {
    "functionName": "getAllAgencyManagementUnits",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/management-unit",
    "verb": "GET",
    "authType": "JWT"
  },
  "createOneAgencyManagementUnit": {
    "functionName": "createOneAgencyManagementUnit",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/management-unit/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateOneAgencyManagementUnit": {
    "functionName": "updateOneAgencyManagementUnit",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/management-unit/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "removeOneAgencyManagementUnit": {
    "functionName": "removeOneAgencyManagementUnit",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/management-unit/remove",
    "verb": "POST",
    "authType": "JWT"
  },
  "importAgenciesOrganizationInformations": {
    "functionName": "importAgenciesOrganizationInformations",
    "path": "https://dev.apistayopen.com/agencies/import/organization-informations",
    "verb": "POST",
    "authType": "JWT"
  },
  "uploadAgencyLogo": {
    "functionName": "uploadAgencyLogo",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/upload-logo",
    "verb": "POST",
    "authType": "JWT"
  },
  "createAgencyAdmin": {
    "functionName": "createAgencyAdmin",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/:agencyId/agency-admin/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateAgencyAdmin": {
    "functionName": "updateAgencyAdmin",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/:agencyId/agency-admin/:agencyAdminId/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "deactivateAgencyAdmin": {
    "functionName": "deactivateAgencyAdmin",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/:agencyId/agency-admin/:agencyAdminId/deactivate",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllAgencyAdmins": {
    "functionName": "getAllAgencyAdmins",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/:agencyId/agency-admin/get",
    "verb": "GET",
    "authType": "JWT"
  },
  "getConnectedAgencyAdmin": {
    "functionName": "getConnectedAgencyAdmin",
    "path": "https://dev.apistayopen.com/connected/agency-admin",
    "verb": "GET",
    "authType": "JWT"
  },
  "getOneAgencyAdminByEmail": {
    "functionName": "getOneAgencyAdminByEmail",
    "path": "https://dev.apistayopen.com/agency-admin/get-by-email",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllManagementUnitsSettingsOfAgencyAdmin": {
    "functionName": "getAllManagementUnitsSettingsOfAgencyAdmin",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/agency-admin/:agencyAdminId/settings/management-units",
    "verb": "GET",
    "authType": "JWT"
  },
  "associateAgencyAdminWithAgencyManagementUnit": {
    "functionName": "associateAgencyAdminWithAgencyManagementUnit",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/agency-admin/:agencyAdminId/settings/management-units/associate",
    "verb": "POST",
    "authType": "JWT"
  },
  "changePasswordForAgencyAdmin": {
    "functionName": "changePasswordForAgencyAdmin",
    "path": "https://dev.apistayopen.com/auth/agency-admin/change-password",
    "verb": "POST",
    "authType": "NONE"
  },
  "changePasswordForBeneficiary": {
    "functionName": "changePasswordForBeneficiary",
    "path": "https://dev.apistayopen.com/auth/beneficiary/change-password",
    "verb": "POST",
    "authType": "JWT"
  },
  "changePasswordForSuperAdmin": {
    "functionName": "changePasswordForSuperAdmin",
    "path": "https://dev.apistayopen.com/auth/super-admin/change-password",
    "verb": "POST",
    "authType": "JWT"
  },
  "createPinForBeneficiary": {
    "functionName": "createPinForBeneficiary",
    "path": "https://dev.apistayopen.com/auth/beneficiary/create-pin",
    "verb": "POST",
    "authType": "NONE"
  },
  "loginForAgencyAdmin": {
    "functionName": "loginForAgencyAdmin",
    "path": "https://dev.apistayopen.com/agency-admin/login",
    "verb": "POST",
    "authType": "NONE"
  },
  "loginForBeneficiary": {
    "functionName": "loginForBeneficiary",
    "path": "https://dev.apistayopen.com/beneficiary/login",
    "verb": "POST",
    "authType": "NONE"
  },
  "loginForBeneficiaryWithPin": {
    "functionName": "loginForBeneficiaryWithPin",
    "path": "https://dev.apistayopen.com/auth/beneficiary/login-with-pin",
    "verb": "POST",
    "authType": "NONE"
  },
  "loginForSuperAdmin": {
    "functionName": "loginForSuperAdmin",
    "path": "https://dev.apistayopen.com/super-admin/login",
    "verb": "POST",
    "authType": "NONE"
  },
  "regeneratePasswordForOpenAdmin": {
    "functionName": "regeneratePasswordForOpenAdmin",
    "path": "https://dev.apistayopen.com/users/:userId/regenerate-password",
    "verb": "POST",
    "authType": "JWT"
  },
  "requestPasswordChangeForAgencyAdmin": {
    "functionName": "requestPasswordChangeForAgencyAdmin",
    "path": "https://dev.apistayopen.com/agency-admin/forgot-password",
    "verb": "POST",
    "authType": "NONE"
  },
  "validateHashForAgencyAdmin": {
    "functionName": "validateHashForAgencyAdmin",
    "path": "https://dev.apistayopen.com/auth/agency-admin/validate-hash",
    "verb": "POST",
    "authType": "NONE"
  },
  "createOneCompany": {
    "functionName": "createOneCompany",
    "path": "https://dev.apistayopen.com/companies/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllCompanies": {
    "functionName": "getAllCompanies",
    "path": "https://dev.apistayopen.com/companies",
    "verb": "GET",
    "authType": "JWT"
  },
  "getAllCompaniesPaginated": {
    "functionName": "getAllCompaniesPaginated",
    "path": "https://dev.apistayopen.com/companies/paginated",
    "verb": "POST",
    "authType": "JWT"
  },
  "getOneCompany": {
    "functionName": "getOneCompany",
    "path": "https://dev.apistayopen.com/companies/:companyId",
    "verb": "GET",
    "authType": "JWT"
  },
  "getOneCompanyByName": {
    "functionName": "getOneCompanyByName",
    "path": "https://dev.apistayopen.com/companies/company-name",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateOneCompany": {
    "functionName": "updateOneCompany",
    "path": "https://dev.apistayopen.com/companies/:companyId/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllBouncesPerPage": {
    "functionName": "getAllBouncesPerPage",
    "path": "https://dev.apistayopen.com/email-event/bounces",
    "verb": "POST",
    "authType": "JWT"
  },
  "resendInvitationEmailAndUpdateBounceSendStatus": {
    "functionName": "resendInvitationEmailAndUpdateBounceSendStatus",
    "path": "https://dev.apistayopen.com/email-event/bounces/:emailEventId/resend-and-update",
    "verb": "POST",
    "authType": "JWT"
  },
  "catchEmailEventsWebhook": {
    "functionName": "catchEmailEventsWebhook",
    "path": "https://dev.apistayopen.com/webhooks/email-event",
    "verb": "POST",
    "authType": "NONE"
  },
  "sendEmail": {
    "functionName": "sendEmail",
    "path": "https://dev.apistayopen.com/email/send",
    "verb": "POST",
    "authType": "JWT"
  },
  "sendInvitationEmailToBeneficiary": {
    "functionName": "sendInvitationEmailToBeneficiary",
    "path": "https://dev.apistayopen.com/email/beneficiary/invitation",
    "verb": "POST",
    "authType": "JWT"
  },
  "sendMailsToUnOnboardedBeneficiaries": {
    "functionName": "sendMailsToUnOnboardedBeneficiaries",
    "path": "https://dev.apistayopen.com/email/unonboarded/invitation",
    "verb": "POST",
    "authType": "JWT"
  },
  "sendResetPasswordEmailToAgencyAdmin": {
    "functionName": "sendResetPasswordEmailToAgencyAdmin",
    "path": "https://dev.apistayopen.com/email/agency-admin/reset-password",
    "verb": "POST",
    "authType": "JWT"
  },
  "sendResyncBridgeCredentialsEmail": {
    "functionName": "sendResyncBridgeCredentialsEmail",
    "path": "https://dev.apistayopen.com/email/resync/invitation",
    "verb": "POST",
    "authType": "JWT"
  },
  "acceptBeneficiaryCgu": {
    "functionName": "acceptBeneficiaryCgu",
    "path": "https://dev.apistayopen.com/beneficiary/:beneficiaryId/accept-cgu",
    "verb": "POST",
    "authType": "JWT"
  },
  "createBeneficiaryPan": {
    "functionName": "createBeneficiaryPan",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/pans/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "createOneBeneficiary": {
    "functionName": "createOneBeneficiary",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/beneficiaries/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "manageBeneficiaryDeactivationSchedule": {
    "functionName": "manageBeneficiaryDeactivationSchedule",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/manageDeactivationSchedule",
    "verb": "POST",
    "authType": "JWT"
  },
  "deleteBeneficiaryPan": {
    "functionName": "deleteBeneficiaryPan",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/pans/:panId/delete",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllBeneficiaries": {
    "functionName": "getAllBeneficiaries",
    "path": "https://dev.apistayopen.com/beneficiaries",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllBeneficiariesByAgencyId": {
    "functionName": "getAllBeneficiariesByAgencyId",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/beneficiaries",
    "verb": "GET",
    "authType": "JWT"
  },
  "getBeneficiariesByFilters": {
    "functionName": "getBeneficiariesByFilters",
    "path": "https://dev.apistayopen.com/beneficiaries/search",
    "verb": "POST",
    "authType": "JWT"
  },
  "getBeneficiariesCountByAgencyId": {
    "functionName": "getBeneficiariesCountByAgencyId",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/beneficiaries/count",
    "verb": "GET",
    "authType": "JWT"
  },
  "getBeneficiaryAppAvailability": {
    "functionName": "getBeneficiaryAppAvailability",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/beneficiaryAppAvailability",
    "verb": "GET",
    "authType": "JWT"
  },
  "getBeneficiaryPans": {
    "functionName": "getBeneficiaryPans",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/pans/get",
    "verb": "GET",
    "authType": "JWT"
  },
  "getExportingAllBeneficiaries": {
    "functionName": "getExportingAllBeneficiaries",
    "path": "https://dev.apistayopen.com/beneficiaries/export",
    "verb": "GET",
    "authType": "JWT"
  },
  "getExportingBeneficiaryBalances": {
    "functionName": "getExportingBeneficiaryBalances",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/balances/export",
    "verb": "GET",
    "authType": "JWT"
  },
  "getExportingBeneficiaryDetail": {
    "functionName": "getExportingBeneficiaryDetail",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/details/export",
    "verb": "GET",
    "authType": "JWT"
  },
  "getBeneficiariesOpenBankingKO": {
    "functionName": "getBeneficiariesOpenBankingKO",
    "path": "https://dev.apistayopen.com/admin/beneficiaries/open-banking-ko",
    "verb": "POST",
    "authType": "JWT"
  },
  "getFullBeneficiaryByIdForAdmin": {
    "functionName": "getFullBeneficiaryByIdForAdmin",
    "path": "https://dev.apistayopen.com/admin/beneficiaries/:beneficiaryId/full",
    "verb": "POST",
    "authType": "JWT"
  },
  "getFullBeneficiaryEmail": {
    "functionName": "getFullBeneficiaryEmail",
    "path": "https://dev.apistayopen.com/beneficiaries/full",
    "verb": "POST",
    "authType": "JWT"
  },
  "getOneBeneficiaryByIdForAdmin": {
    "functionName": "getOneBeneficiaryByIdForAdmin",
    "path": "https://dev.apistayopen.com/admin/beneficiaries/:beneficiaryId/get",
    "verb": "GET",
    "authType": "JWT"
  },
  "changeBeneficiaryMode": {
    "functionName": "changeBeneficiaryMode",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/change-mode",
    "verb": "POST",
    "authType": "JWT"
  },
  "resetBeneficiaryMode": {
    "functionName": "resetBeneficiaryMode",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/reset-mode",
    "verb": "POST",
    "authType": "JWT"
  },
  "resetBeneficiaryOpenBankingSync": {
    "functionName": "resetBeneficiaryOpenBankingSync",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/reset-sync",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateBeneficiaryActivity": {
    "functionName": "updateBeneficiaryActivity",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/activity",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateBeneficiaryRestrictedSettings": {
    "functionName": "updateBeneficiaryRestrictedSettings",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/update-restricted-settings",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateBeneficiaryEmailForAgency": {
    "functionName": "updateBeneficiaryEmailForAgency",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/beneficiaries/:beneficiaryId/email/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateBeneficiaryIbanForAgency": {
    "functionName": "updateBeneficiaryIbanForAgency",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/beneficiaries/:beneficiaryId/iban/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateBeneficiaryMode": {
    "functionName": "updateBeneficiaryMode",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/update-mode",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateBeneficiaryPan": {
    "functionName": "updateBeneficiaryPan",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/pans/:panId/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateBeneficiaryRef": {
    "functionName": "updateBeneficiaryRef",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/beneficiaries/:beneficiaryId/ref",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateIbanBeneficiary": {
    "functionName": "updateIbanBeneficiary",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/iban",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateOneBeneficiary": {
    "functionName": "updateOneBeneficiary",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/beneficiaries/:beneficiaryId/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "uploadBeneficiaries": {
    "functionName": "uploadBeneficiaries",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/beneficiaries/upload",
    "verb": "POST",
    "authType": "JWT"
  },
  "getBeneficiaryPaymentInformation": {
    "functionName": "getBeneficiaryPaymentInformation",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/payment-information",
    "verb": "GET",
    "authType": "JWT"
  },
  "updateBeneficiariesClassification": {
    "functionName": "updateBeneficiariesClassification",
    "path": "https://dev.apistayopen.com/beneficiaries/update-classification",
    "verb": "POST",
    "authType": "JWT"
  },
  "associateBeneficiariesWithManagementUnit": {
    "functionName": "associateBeneficiariesWithManagementUnit",
    "path": "https://dev.apistayopen.com/beneficiaries/management-units/associate",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateBeneficiarySettings": {
    "functionName": "updateBeneficiarySettings",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/settings",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgencyInvoices": {
    "functionName": "getAgencyInvoices",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/invoices",
    "verb": "POST",
    "authType": "JWT"
  },
  "getInvoiceBeneficiariesByDate": {
    "functionName": "getInvoiceBeneficiariesByDate",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/invoices/beneficiaries",
    "verb": "POST",
    "authType": "JWT"
  },
  "cleanDatabases": {
    "functionName": "cleanDatabases",
    "path": "https://dev.apistayopen.com/clean-databases",
    "verb": "POST",
    "authType": "JWT"
  },
  "getFeatureFlags": {
    "functionName": "getFeatureFlags",
    "path": "https://dev.apistayopen.com/config/feature-flags",
    "verb": "POST",
    "authType": "NONE"
  },
  "logMobile": {
    "functionName": "logMobile",
    "path": "https://dev.apistayopen.com/maintenance/logMobile",
    "verb": "POST",
    "authType": "NONE"
  },
  "maintenance": {
    "functionName": "maintenance",
    "path": "https://dev.apistayopen.com/maintenance",
    "verb": "POST",
    "authType": "JWT"
  },
  "test": {
    "functionName": "test",
    "path": "https://dev.apistayopen.com/test",
    "verb": "POST",
    "authType": "NONE"
  },
  "startMillesimeExecution": {
    "functionName": "startMillesimeExecution",
    "path": "https://dev.apistayopen.com/millesime/start-execution",
    "verb": "POST",
    "authType": "JWT"
  },
  "getCurrentMillesimeMetadata": {
    "functionName": "getCurrentMillesimeMetadata",
    "path": "https://dev.apistayopen.com/millesime/metadata",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgenciesOptionsForCurrentMillesime": {
    "functionName": "getAgenciesOptionsForCurrentMillesime",
    "path": "https://dev.apistayopen.com/millesime/agencies",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgencyCurrentMillesimeStatus": {
    "functionName": "getAgencyCurrentMillesimeStatus",
    "path": "https://dev.apistayopen.com/millesime/agencies/:agencyId/status",
    "verb": "GET",
    "authType": "JWT"
  },
  "setAgencyRightsReconductionType": {
    "functionName": "setAgencyRightsReconductionType",
    "path": "https://dev.apistayopen.com/millesime/agencies/:agencyId/rights-reconduction",
    "verb": "POST",
    "authType": "JWT"
  },
  "resetAgencyRightsReconductionType": {
    "functionName": "resetAgencyRightsReconductionType",
    "path": "https://dev.apistayopen.com/millesime/agencies/:agencyId/rights-reconduction/reset",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgencyMillesimeReports": {
    "functionName": "getAgencyMillesimeReports",
    "path": "https://dev.apistayopen.com/millesime/agencies/:agencyId/reports",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgencyBeneficiariesMillesimeReports": {
    "functionName": "getAgencyBeneficiariesMillesimeReports",
    "path": "https://dev.apistayopen.com/millesime/agencies/:agencyId/beneficiaries-reports",
    "verb": "POST",
    "authType": "JWT"
  },
  "addSyncedBankAccounts": {
    "functionName": "addSyncedBankAccounts",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/bank-accounts/add-sync",
    "verb": "POST",
    "authType": "JWT"
  },
  "forceToHandleLastPaybacks": {
    "functionName": "forceToHandleLastPaybacks",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/paybacks/force-to-handle",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAvailableBankAccounts": {
    "functionName": "getAvailableBankAccounts",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/bank-accounts",
    "verb": "GET",
    "authType": "JWT"
  },
  "getSyncedBankAccountStatus": {
    "functionName": "getSyncedBankAccountStatus",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/bank-accounts/sync-status",
    "verb": "GET",
    "authType": "JWT"
  },
  "selectBankAccount": {
    "functionName": "selectBankAccount",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/bank-accounts/:bankAccountId/select",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateSyncedBankAccounts": {
    "functionName": "updateSyncedBankAccounts",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/bank-accounts/update-sync",
    "verb": "POST",
    "authType": "JWT"
  },
  "createAgencyOrder": {
    "functionName": "createAgencyOrder",
    "path": "https://dev.apistayopen.com/agencies/orders/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgencyOrderProvision": {
    "functionName": "getAgencyOrderProvision",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/orders/:orderId/provisions",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgencyOrderProvisionSummary": {
    "functionName": "getAgencyOrderProvisionSummary",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/orders/:orderId/provisions-summary",
    "verb": "GET",
    "authType": "JWT"
  },
  "getBeneficiaryProvisions": {
    "functionName": "getBeneficiaryProvisions",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/beneficiaries/:beneficiaryId/provisions",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateOrderDate": {
    "functionName": "updateOrderDate",
    "path": "https://dev.apistayopen.com/orders/update-date",
    "verb": "POST",
    "authType": "JWT"
  },
  "getPaybacksCountForAdmin": {
    "functionName": "getPaybacksCountForAdmin",
    "path": "https://dev.apistayopen.com/admin/paybacks/count",
    "verb": "POST",
    "authType": "JWT"
  },
  "getBeneficiaryPaybacksCount": {
    "functionName": "getBeneficiaryPaybacksCount",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/paybacks/count",
    "verb": "POST",
    "authType": "JWT"
  },
  "getNewPaybacksCountForMobile": {
    "functionName": "getNewPaybacksCountForMobile",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/paybacks/new-count",
    "verb": "GET",
    "authType": "JWT"
  },
  "resetPaybacksCountForMobile": {
    "functionName": "resetPaybacksCountForMobile",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/paybacks/reset-count",
    "verb": "POST",
    "authType": "JWT"
  },
  "getOnePaybackForAdmin": {
    "functionName": "getOnePaybackForAdmin",
    "path": "https://dev.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/:paybackId/get",
    "verb": "GET",
    "authType": "JWT"
  },
  "getPaginatedPaybacksForAdmin": {
    "functionName": "getPaginatedPaybacksForAdmin",
    "path": "https://dev.apistayopen.com/admin/paybacks/paginated",
    "verb": "POST",
    "authType": "JWT"
  },
  "getPaginatedPaybacks": {
    "functionName": "getPaginatedPaybacks",
    "path": "https://dev.apistayopen.com/paybacks/paginated",
    "verb": "POST",
    "authType": "JWT"
  },
  "getExportingPaybacksForAdmin": {
    "functionName": "getExportingPaybacksForAdmin",
    "path": "https://dev.apistayopen.com/admin/paybacks/export",
    "verb": "POST",
    "authType": "JWT"
  },
  "getBeneficiaryPaybacks": {
    "functionName": "getBeneficiaryPaybacks",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/paybacks/list",
    "verb": "POST",
    "authType": "JWT"
  },
  "getPaybacksForMobile": {
    "functionName": "getPaybacksForMobile",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/paybacks",
    "verb": "GET",
    "authType": "JWT"
  },
  "getBeneficiaryUncategorizedTransactions": {
    "functionName": "getBeneficiaryUncategorizedTransactions",
    "path": "https://dev.apistayopen.com/admin/beneficiaries/:beneficiaryId/transactions/uncategorized",
    "verb": "POST",
    "authType": "JWT"
  },
  "createPaybackForScanCB": {
    "functionName": "createPaybackForScanCB",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/paybacks/create-scan-cb",
    "verb": "POST",
    "authType": "JWT"
  },
  "createPaybackTransaction": {
    "functionName": "createPaybackTransaction",
    "path": "https://dev.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/create-transaction",
    "verb": "POST",
    "authType": "JWT"
  },
  "updatePayback": {
    "functionName": "updatePayback",
    "path": "https://dev.apistayopen.com/paybacks/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "addBusinessToPayback": {
    "functionName": "addBusinessToPayback",
    "path": "https://dev.apistayopen.com/paybacks/add-business",
    "verb": "POST",
    "authType": "JWT"
  },
  "resolvePendingBusinessPaybackByAdmin": {
    "functionName": "resolvePendingBusinessPaybackByAdmin",
    "path": "https://dev.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/:paybackId/resolve-business",
    "verb": "POST",
    "authType": "JWT"
  },
  "rejectPendingBusinessPaybackByAdmin": {
    "functionName": "rejectPendingBusinessPaybackByAdmin",
    "path": "https://dev.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/:paybackId/reject-business",
    "verb": "POST",
    "authType": "JWT"
  },
  "resolvePendingOcrPaybackByAdmin": {
    "functionName": "resolvePendingOcrPaybackByAdmin",
    "path": "https://dev.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/:paybackId/resolve-ocr",
    "verb": "POST",
    "authType": "JWT"
  },
  "rejectPendingOcrPaybackByAdmin": {
    "functionName": "rejectPendingOcrPaybackByAdmin",
    "path": "https://dev.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/:paybackId/reject-ocr",
    "verb": "POST",
    "authType": "JWT"
  },
  "processBeneficiaryPayoutsByAdmin": {
    "functionName": "processBeneficiaryPayoutsByAdmin",
    "path": "https://dev.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/process-payouts",
    "verb": "POST",
    "authType": "JWT"
  },
  "rollbackRejectedPaybackByAdmin": {
    "functionName": "rollbackRejectedPaybackByAdmin",
    "path": "https://dev.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/:paybackId/rollback",
    "verb": "POST",
    "authType": "JWT"
  },
  "moveForwardRejectedPaybackByAdmin": {
    "functionName": "moveForwardRejectedPaybackByAdmin",
    "path": "https://dev.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/:paybackId/move-forward",
    "verb": "POST",
    "authType": "JWT"
  },
  "forcePaymentForRejectedBusinessPaybackByAdmin": {
    "functionName": "forcePaymentForRejectedBusinessPaybackByAdmin",
    "path": "https://dev.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/:paybackId/force-payment",
    "verb": "POST",
    "authType": "JWT"
  },
  "handlePendingSelectionPaybackByBeneficiary": {
    "functionName": "handlePendingSelectionPaybackByBeneficiary",
    "path": "https://dev.apistayopen.com/paybacks/handle-pending-selection",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgencyPayinReports": {
    "functionName": "getAgencyPayinReports",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/payins/reports",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgencyPayins": {
    "functionName": "getAgencyPayins",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/payins",
    "verb": "GET",
    "authType": "JWT"
  },
  "getPayinsByorderId": {
    "functionName": "getPayinsByorderId",
    "path": "https://dev.apistayopen.com/orders/:orderId/payins",
    "verb": "GET",
    "authType": "JWT"
  },
  "processPayinRefund": {
    "functionName": "processPayinRefund",
    "path": "https://dev.apistayopen.com/payins/:payinId/refund",
    "verb": "POST",
    "authType": "JWT"
  },
  "addBeneficiaryPushToken": {
    "functionName": "addBeneficiaryPushToken",
    "path": "https://dev.apistayopen.com/notifications/beneficiaries/:beneficiaryId/add-token",
    "verb": "POST",
    "authType": "JWT"
  },
  "sendPushNotificationToBeneficiary": {
    "functionName": "sendPushNotificationToBeneficiary",
    "path": "https://dev.apistayopen.com/beneficiaries/push-notification",
    "verb": "POST",
    "authType": "JWT"
  },
  "createMaskPaymentDescriptor": {
    "functionName": "createMaskPaymentDescriptor",
    "path": "https://dev.apistayopen.com/admin/referential-actions/:agencyUid/:listType/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "deleteMaskPaymentDescriptor": {
    "functionName": "deleteMaskPaymentDescriptor",
    "path": "https://dev.apistayopen.com/admin/referential-actions/delete-mask",
    "verb": "POST",
    "authType": "JWT"
  },
  "getCountPaymentDescriptor": {
    "functionName": "getCountPaymentDescriptor",
    "path": "https://dev.apistayopen.com/admin/referential-actions/:agencyUid/count",
    "verb": "GET",
    "authType": "JWT"
  },
  "getOneMaskPaymentDescriptor": {
    "functionName": "getOneMaskPaymentDescriptor",
    "path": "https://dev.apistayopen.com/admin/referential-actions/:agencyUid/:listType/get-one-mask",
    "verb": "POST",
    "authType": "JWT"
  },
  "getListPaymentDescriptor": {
    "functionName": "getListPaymentDescriptor",
    "path": "https://dev.apistayopen.com/admin/referential-actions/:agencyUid/:listType/list",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateMaskPaymentDescriptor": {
    "functionName": "updateMaskPaymentDescriptor",
    "path": "https://dev.apistayopen.com/admin/referential-actions/:agencyUid/:listType/update-mask",
    "verb": "POST",
    "authType": "JWT"
  },
  "createOpenAdmin": {
    "functionName": "createOpenAdmin",
    "path": "https://dev.apistayopen.com/users/create-open-admin",
    "verb": "POST",
    "authType": "JWT"
  },
  "editUserRole": {
    "functionName": "editUserRole",
    "path": "https://dev.apistayopen.com/users/edit-role",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllUsers": {
    "functionName": "getAllUsers",
    "path": "https://dev.apistayopen.com/users/all",
    "verb": "POST",
    "authType": "JWT"
  },
  "getUserById": {
    "functionName": "getUserById",
    "path": "https://dev.apistayopen.com/users/:userId/get-one",
    "verb": "GET",
    "authType": "JWT"
  },
  "getUserByToken": {
    "functionName": "getUserByToken",
    "path": "https://dev.apistayopen.com/users/get-by-token",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateUserEmail": {
    "functionName": "updateUserEmail",
    "path": "https://dev.apistayopen.com/users/:userId/update-email",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllVat": {
    "functionName": "getAllVat",
    "path": "https://dev.apistayopen.com/vat/rates",
    "verb": "GET",
    "authType": "JWT"
  },
  "isSundayOrPublicHoliday": {
    "functionName": "isSundayOrPublicHoliday",
    "path": "https://dev.apistayopen.com/calendar/is-sunday-or-holiday",
    "verb": "POST",
    "authType": "NONE"
  },
  "getCountryFlags": {
    "functionName": "getCountryFlags",
    "path": "https://dev.apistayopen.com/country-flags",
    "verb": "GET",
    "authType": "NONE"
  },
  "processBeneficiaryDeactivation": {
    "functionName": "processBeneficiaryDeactivation",
    "path": "https://dev.apistayopen.com/tasks/processBeneficiaryDeactivation",
    "verb": "POST",
    "authType": "NONE"
  },
  "getBusinessInfo": {
    "functionName": "getBusinessInfo",
    "path": "https://dev.apistayopen.com/businesses/:identifier/info",
    "verb": "GET",
    "authType": "JWT"
  },
  "searchBusinessByCriteria": {
    "functionName": "searchBusinessByCriteria",
    "path": "https://dev.apistayopen.com/businesses/search-by-criteria",
    "verb": "POST",
    "authType": "JWT"
  },
  "searchPappersBusinesses": {
    "functionName": "searchPappersBusinesses",
    "path": "https://dev.apistayopen.com/businesses/search-pappers",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllBusinessesWithPagination": {
    "functionName": "getAllBusinessesWithPagination",
    "path": "https://dev.apistayopen.com/businesses/all",
    "verb": "POST",
    "authType": "JWT"
  },
  "createOneBusiness": {
    "functionName": "createOneBusiness",
    "path": "https://dev.apistayopen.com/businesses/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "createOneBusinessFromSiret": {
    "functionName": "createOneBusinessFromSiret",
    "path": "https://dev.apistayopen.com/businesses/create-from-siret",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateOneBusiness": {
    "functionName": "updateOneBusiness",
    "path": "https://dev.apistayopen.com/businesses/:businessId/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllContractByBusinessId": {
    "functionName": "getAllContractByBusinessId",
    "path": "https://dev.apistayopen.com/businesses/:businessId/contracts",
    "verb": "GET",
    "authType": "JWT"
  },
  "createOneContract": {
    "functionName": "createOneContract",
    "path": "https://dev.apistayopen.com/businesses/:businessId/contracts/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateOneContract": {
    "functionName": "updateOneContract",
    "path": "https://dev.apistayopen.com/businesses/:businessId/contracts/:contractId/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "deleteOneContract": {
    "functionName": "deleteOneContract",
    "path": "https://dev.apistayopen.com/businesses/:businessId/contracts/:contractId/delete",
    "verb": "POST",
    "authType": "JWT"
  },
  "getOneBusinessInvoices": {
    "functionName": "getOneBusinessInvoices",
    "path": "https://dev.apistayopen.com/business/:businessId/invoices",
    "verb": "GET",
    "authType": "JWT"
  },
  "getBusinessBillingInfo": {
    "functionName": "getBusinessBillingInfo",
    "path": "https://dev.apistayopen.com/businesses/billing",
    "verb": "POST",
    "authType": "JWT"
  },
  "launchBilling": {
    "functionName": "launchBilling",
    "path": "https://dev.apistayopen.com/businesses/billing/launch",
    "verb": "POST",
    "authType": "JWT"
  },
  "getBusinessesUser": {
    "functionName": "getBusinessesUser",
    "path": "https://dev.apistayopen.com/businesses/:businessId/users",
    "verb": "GET",
    "authType": "JWT"
  },
  "createOrUpdateBusinessAdminOrCashier": {
    "functionName": "createOrUpdateBusinessAdminOrCashier",
    "path": "https://dev.apistayopen.com/businesses/:businessId/users/upsert",
    "verb": "POST",
    "authType": "JWT"
  },
  "sendBusinessUserResetPassword": {
    "functionName": "sendBusinessUserResetPassword",
    "path": "https://dev.apistayopen.com/businesses/users/reset-password",
    "verb": "POST",
    "authType": "JWT"
  },
  "initiateDiscreteContractBetweenAgencyAndBusiness": {
    "functionName": "initiateDiscreteContractBetweenAgencyAndBusiness",
    "path": "https://dev.apistayopen.com/contracted-businesses/initiate",
    "verb": "POST",
    "authType": "JWT"
  },
  "getLatestBalance": {
    "functionName": "getLatestBalance",
    "path": "https://dev.apistayopen.com/balances/latest",
    "verb": "POST",
    "authType": "JWT"
  },
  "insertBalance": {
    "functionName": "insertBalance",
    "path": "https://dev.apistayopen.com/balances/insert",
    "verb": "POST",
    "authType": "JWT"
  },
  "refreshBalances": {
    "functionName": "refreshBalances",
    "path": "https://dev.apistayopen.com/balances/refresh",
    "verb": "POST",
    "authType": "JWT"
  },
  "getBalances": {
    "functionName": "getBalances",
    "path": "https://dev.apistayopen.com/balances/get",
    "verb": "POST",
    "authType": "JWT"
  },
  "calculateRemainingRightsForPeriod": {
    "functionName": "calculateRemainingRightsForPeriod",
    "path": "https://dev.apistayopen.com/balances/remaining-rights",
    "verb": "POST",
    "authType": "JWT"
  },
  "webhook": {
    "functionName": "webhook",
    "path": "https://dev.apistayopen.com/payment/webhook",
    "verb": "POST",
    "authType": "JWT"
  },
  "documentSignatureFlow": {
    "functionName": "documentSignatureFlow",
    "path": "https://dev.apistayopen.com/documents/signature_flow",
    "verb": "POST",
    "authType": "JWT"
  },
  "webhookDocument": {
    "functionName": "webhookDocument",
    "path": "https://dev.apistayopen.com/documents/webhook",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgencyPaymentTransactions": {
    "functionName": "getAgencyPaymentTransactions",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/:agencyId/payment-transactions",
    "verb": "POST",
    "authType": "JWT"
  },
  "getBeneficiaryPaymentTransactions": {
    "functionName": "getBeneficiaryPaymentTransactions",
    "path": "https://dev.apistayopen.com/beneficiaries/:beneficiaryId/payment-transactions",
    "verb": "POST",
    "authType": "JWT"
  },
  "updatePaymentTransactionVerificationStatus": {
    "functionName": "updatePaymentTransactionVerificationStatus",
    "path": "https://dev.apistayopen.com/payment-transactions/:paymentTransactionId/verification-status",
    "verb": "PUT",
    "authType": "JWT"
  },
  "getPaymentTransactionsCountForAgencyWallet": {
    "functionName": "getPaymentTransactionsCountForAgencyWallet",
    "path": "https://dev.apistayopen.com/companies/:companyId/agencies/:agencyId/payment-transactions/count",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgencyMillesimeReceipts": {
    "functionName": "getAgencyMillesimeReceipts",
    "path": "https://dev.apistayopen.com/agencies/:agencyId/millesime-receipts",
    "verb": "POST",
    "authType": "JWT"
  }
} as const;