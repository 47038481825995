import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { from, Observable, of, switchMap, take } from 'rxjs';
import { Auth, user as afUser, User as AFUser } from '@angular/fire/auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  #afAuth: Auth = inject(Auth);
  #accessToken$: Observable<string | null> = afUser(this.#afAuth)
    .pipe(
      switchMap((user: AFUser | null) => !!user
        ? from(user!.getIdToken())
        : of(null)),
    );

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const needAuth: boolean = request.headers.get('authType') === 'JWT';

    if (!needAuth) {
      return next.handle(request);
    }

    return this.#accessToken$.pipe(
      take(1),
      switchMap((token: string | null) => this.#createNewAccessRequest(request, token || '')),
      switchMap((accessRequest: HttpRequest<unknown>) => next.handle(accessRequest)),
    );
  }

  #createNewAccessRequest(request: HttpRequest<unknown>, accessToken: string): Observable<HttpRequest<unknown>> {
    return of(request.clone({
      setHeaders: {
        'sebauth': accessToken,
      },
    }));
  }
}
