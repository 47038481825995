import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpContextToken,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorsService } from '@services/errors.service';

export const BYPASS_ERROR_HANDLER = new HttpContextToken(() => false);

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  #errorsService: ErrorsService = inject(ErrorsService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (request.context.get(BYPASS_ERROR_HANDLER)) {
          return throwError(() => error);
        }
        return this.#errorsService.manage(error);
      }),
    );
  }
}
