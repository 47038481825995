import { inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from '@services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard  {
  #authService: AuthService = inject(AuthService);
  #router: Router = inject(Router);

  canActivateChild(): Observable<boolean | UrlTree> {
    return this.#authService.isLoggedIn$.pipe(
      switchMap((isLoggedIn: boolean) => {
        return isLoggedIn
          ? of(this.#router.parseUrl(''))
          : of(true);
      }),
    );
  }
}
