import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, Observable } from 'rxjs';
import { LayoutConfig } from './page-header.model';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {

  config: LayoutConfig = {
    isCollapsed: true,
  };
  config$: BehaviorSubject<LayoutConfig> = new BehaviorSubject<LayoutConfig>(this.config);
  private isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private message: NzMessageService,
  ) {
  }

  getLoadingState$(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  startLoading(): this {
    this.isLoading$.next(true);
    return this;
  }

  stopLoading(): this {
    this.isLoading$.next(false);
    return this;
  }

  clearConfig(): this {
    this.config$.next({});
    this.config = {};
    return this;
  }

  setConfig(config: LayoutConfig): this {
    this.config$.next({});
    this.config = { ...this.config, ...config };
    this.config$.next(this.config);
    return this;
  }

  setBreadCrumb(breadCrumbs: string[]): this {
    this.config = { ...this.config, breadCrumbs };
    this.config$.next(this.config);
    return this;
  }

  resetConfig(): this {
    this.setConfig({
      title: '',
      subTitle: '',
      isPinky: false,
      isPublicGroup: false,
      isTransparent: false,
    });
    return this;
  }

  getConfig$(): Observable<LayoutConfig> {
    return this.config$.asObservable();
  }

  openMenu(): void {
    this.config.isCollapsed = false;
    this.setConfig(this.config);
  }

  closeMenu(): void {
    this.config.isCollapsed = true;
    this.setConfig(this.config);

  }

  createSuccessMessage(text: string, durationInMilliseconds: number): void {
    this.message.success(text, {
      nzDuration: durationInMilliseconds,
    });
  }

  createWarningMessage(text: string, durationInMilliseconds: number): void {
    this.message.warning(text, {
      nzDuration: durationInMilliseconds,
    });
  }

  createErrorMessage(text: string, durationInMilliseconds: number): void {
    this.message.error(text, {
      nzDuration: durationInMilliseconds,
    });
  }
}
