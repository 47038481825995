import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { UserModel } from '@assets/models/user/User.model';
import { UserRoleType } from '@assets/types/UserRole.type';
import { CallerService } from './caller.service';
import { GetFullUserResponse } from '@assets/responses/users/GetFullUserById.response';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  #user$: BehaviorSubject<UserModel | null> = new BehaviorSubject<UserModel | null>(null);
  #callerService: CallerService = inject(CallerService);

  get isTransactionManager$(): Observable<boolean> {
    return this.#hasRole$('TRANSACTION_MANAGER');
  }

  get isSupport$() {
    return this.#hasRole$('SUPPORT');
  }

  get isSupportAdmin$(): Observable<boolean> {
    return this.#hasRole$('SUPPORT_ADMIN');
  }

  get isSuperAdmin$(): Observable<boolean> {
    return this.#hasRole$('SUPER_ADMIN');
  }

  setUser(user?: UserModel | null): void {
    if (!this.#isAdmin(user)) {
      throw new Error(`Access not allowed`);
    }
    this.#user$.next(user);
  }

  clearUser(): void {
    this.#user$.next(null);
  }

  initUserById$(userUid: string): Observable<void> {
    return this.#callerService.callable(
        'getUserById',
        { userId: userUid },
      ).pipe(
        tap(({ user }: GetFullUserResponse) => this.setUser(user)),
        map(() => void(0)),
      );
  }

  #isAdmin(user?: UserModel | null): user is UserModel {
    const isSuperAdmin: boolean = !!user?.roles.includes('SUPER_ADMIN');
    const isOpenAdmin: boolean = !!user?.roles.includes('OPEN_ADMIN');
    return isSuperAdmin || isOpenAdmin;
  }

  #hasRole$(role: UserRoleType): Observable<boolean> {
    return this.#user$.pipe(
      map(user => !!user?.roles.includes(role)),
    );
  }
}
