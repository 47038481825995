import { Component } from '@angular/core';
import { ConfigurationService } from '@services/configuration.service';
import { map, Observable, shareReplay } from 'rxjs';
import { FeatureFlagsConfigValue } from '@assets/models/configuration/featureFlags.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  title = 'Admin Open!';

  private appAvailabilityFlagValue$: Observable<FeatureFlagsConfigValue> = this.configService.getFlagValue$('appAvailability', false).pipe(
    shareReplay(1),
  );
  loadingAvailability$: Observable<boolean> = this.appAvailabilityFlagValue$.pipe(
    map(() => !this.configService.isReady()),
  );
  isAvailable$: Observable<boolean> = this.appAvailabilityFlagValue$.pipe(
    map(({ enabled }: { enabled: boolean }) => enabled),
  );
  unavailabilityMessage$: Observable<string | undefined> = this.appAvailabilityFlagValue$.pipe(
    map(({ message }: { message?: string }) => message),
  );

  constructor(private configService: ConfigurationService) {
  }
}
