import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { LayoutService } from "../components/layouts/layout.service";

@Injectable()
export class LayoutInterceptor implements HttpInterceptor {

  constructor(private layoutService: LayoutService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.layoutService.startLoading();
    return next.handle(request).pipe(
        tap(() => this.layoutService.stopLoading()),
      );
  }
}
