import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppUnavailableComponent } from '@components/maintenance/maintenance/app-unavailable.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { fr_FR, NZ_I18N } from 'ng-zorro-antd/i18n';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';
import { LayoutInterceptor } from './interceptors/layout.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { environment } from '@environments/environment';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { connectFirestoreEmulator, Firestore, getFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, Functions, getFunctions, provideFunctions } from '@angular/fire/functions';

registerLocaleData(fr);

@NgModule({
  declarations: [
    AppComponent,
    AppUnavailableComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NzMessageModule,
    NzModalModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    NzSpinModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.name === 'local') {
        connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
      }
      return auth;
    }),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.name === 'local') {
        connectStorageEmulator(storage, 'localhost', 9099);
      }
      return storage;
    }),
    provideFirestore(() => {
      const firestore: Firestore = getFirestore();
      if (environment.name === 'local') {
        connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
      }
      return firestore;
    }),
    provideFunctions(() => {
      const functions: Functions = getFunctions();
      if (environment.name === 'local') {
        connectFunctionsEmulator(functions, '127.0.0.1', 4089);
      }
      functions.region = 'europe-west3';
      return functions;
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LayoutInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: NZ_I18N, useValue: fr_FR },
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
